import { FC } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { SubmitButtonContext } from '@contexts/SubmitButton';

import { ROUTES, ROUTE_IS_READY } from '@constants';
import {
  Login,
  ForgotPassword,
  Signup,
  Users,
  CreatePassword,
  NotFound,
  UserDetails,
  AccountSettings,
  BusinessAccountsTabs,
  ContentCreatorTabs,
  LinksManagement,
  AssetAccountTabs,
  SocialAuth,
  Whitelist,
  NotificationPage,
  WorkingMode,
} from '@pages';
import { ProtectedRoute, PublicRoute, Loader } from '@atoms';
import { useUser } from '@hooks/useUser';
import { AdminRoute } from '@atoms/AdminRoute';
import {
  BusinessAccountsDetails,
  CCModelDetails,
  CCAssetDetails,
  MemberDetails,
  AssetAccountDetails,
  WorkspaceTable,
  // NewContentCreatorTable,
  // NewBusinessAccountsTable,
  ContentCreatorTable,
  // ScanManagementTable,
  // ScanManagementDetails,
  ScanSettings,
  UploadDocument,
  BusinessAccountsTable,
  AddWhitelistLinkModal,
  Billing,
} from '@organisms';
import { AssignAssetModal, MoveAssetModal } from '@organisms/Modals';
import { AccountsTabs } from '@templates';
import {
  WorkingModeFalsePositive,
  WorkingModeInfringing,
  WorkingModeRaw,
} from '@pages/WorkingMode/utils';
import { AllNotification } from '@pages/Notification/AllNotification';
import { ESignPage } from '@pages/ESign';
import { ActionLog } from '@pages/ActionLog';
import { CopyrightPage } from '@pages/CopyrightContent/CopyrightPage';
import SocialMedia from '@pages/SocialMedia';
// import { History } from '@pages/History';

export const AppRouter: FC = () => {
  const { user, isLoading } = useUser();

  return (
    <Loader isLoading={isLoading}>
      <Routes>
        <Route element={<PublicRoute isAuthorized={!!user} />}>
          <Route path={ROUTES.Login} element={<Login />} />
          <Route path={ROUTES.Signup} element={<Signup />} />
          <Route path={ROUTES.ForgotPassword} element={<ForgotPassword />} />
          <Route path={ROUTES.Home} element={<Navigate to={ROUTES.Login} />} />
        </Route>
        <Route element={<PublicRoute isAuthorized={false} />}>
          <Route
            path={ROUTES.CreateNewPassword}
            element={<CreatePassword isAuthorized={!!user} />}
          />
          <Route path={ROUTES.SocialAuth} element={<SocialAuth />} />
        </Route>
        <Route element={<ProtectedRoute isAuthorized={!!user} />}>
          <Route element={<AdminRoute />}>
            <Route path={ROUTES.Users} element={<Users />}>
              <Route path={`:userId/${ROUTES.Assign}`} element={<AssignAssetModal />} />
            </Route>
            <Route
              path={`${ROUTES.Users}/:userId`}
              element={
                <SubmitButtonContext>
                  <UserDetails />
                </SubmitButtonContext>
              }
            />
          </Route>
          {/* <Route path={ROUTES.History} element={<History />} /> */}
          <Route path={ROUTES.Links} element="Links" />
          <Route path={ROUTES.Settings} element={<AccountSettings />} />
          {ROUTE_IS_READY.ACTION_LOGS && <Route path={ROUTES.ActionLog} element={<ActionLog />} />}
          <Route
            path={ROUTES.Accounts}
            element={<AccountsTabs title="Accounts" hasCreateAccount />}
          >
            <Route index element={<ContentCreatorTable />} />
            <Route path={ROUTES.ContentCreators} element={<ContentCreatorTable />} />
            <Route path={ROUTES.BusinessAccounts} element={<BusinessAccountsTable />}>
              <Route path={`:id/${ROUTES.Move}`} element={<MoveAssetModal />} />
            </Route>
          </Route>
          {/* <Route path={ROUTES.NewClients} element={<AccountsTabs title="New clients" />}>
            <Route path={ROUTES.ContentCreators} element={<NewContentCreatorTable />} />
            <Route path={ROUTES.BusinessAccounts} element={<NewBusinessAccountsTable />} />
          </Route> */}
          {/* <Route path={ROUTES.Whitelist} element={<AccountsTabs title="Whitelist" hasAddWhitelist />}>
            <Route path={ROUTES.ContentCreators} element={<Whitelist />} />
            <Route path={ROUTES.BusinessAccounts} element={<Whitelist />} />
            <Route path={`:companyRoute/${ROUTES.Add}`} element={<AddWhitelistLinkModal />} />
          </Route> */}
          <Route path={`${ROUTES.BusinessAccounts}/:id`} element={<BusinessAccountsTabs />}>
            <Route index element={<BusinessAccountsDetails />} />
            <Route path={ROUTES.AccountDetails} element={<BusinessAccountsDetails />}>
              <Route path={ROUTES.Move} element={<MoveAssetModal />} />
            </Route>
            <Route path={ROUTES.Workspace} element={<WorkspaceTable />} />
            <Route path={ROUTES.Billing} element={<Billing />} />
            <Route path={ROUTES.Whitelist} element={<Whitelist />} />
            <Route
              path={`:companyRoute/${ROUTES.Add}`}
              element={<AddWhitelistLinkModal isBusiness />}
            />
            <Route path={ROUTES.Notification} element={<NotificationPage title="Notification" />} />
          </Route>
          <Route path={`${ROUTES.AssetAccount}/:type/:id/:assetId/`} element={<AssetAccountTabs />}>
            <Route index element={<AssetAccountDetails />} />
            <Route path={ROUTES.AccountDetails} element={<AssetAccountDetails />} />
            <Route
              path={ROUTES.LinksManagement}
              element={<LinksManagement queryKey="asset-account-details" />}
            />
            <Route path={ROUTES.ScanSettings} element={<ScanSettings />} />
            <Route path={ROUTES.UploadDocument} element={<UploadDocument />} />
            <Route
              path={ROUTES.Notification}
              element={<NotificationPage title="Notification" isModel />}
            />
          </Route>
          <Route path={`${ROUTES.ContentCreators}/:id/:assetId/`} element={<ContentCreatorTabs />}>
            <Route index element={<CCModelDetails />} />
            <Route path={ROUTES.ModelDetails} element={<CCModelDetails />} />
            <Route
              path={ROUTES.LinksManagement}
              element={<LinksManagement queryKey="content-creator-data-asset" />}
            />
            <Route path={ROUTES.SocialMedia} element={<SocialMedia />} />
            <Route path={ROUTES.ScanSettings} element={<ScanSettings />} />
            <Route path={ROUTES.UploadDocument} element={<UploadDocument />} />
            <Route path={ROUTES.Whitelist} element={<Whitelist />} />
            <Route path={`:companyRoute/${ROUTES.Add}`} element={<AddWhitelistLinkModal />} />
            <Route path={ROUTES.Notification} element={<NotificationPage title="Notification" />} />
          </Route>
          <Route path={`${ROUTES.ContentCreators}/:id/`} element={<ContentCreatorTabs hideTabs />}>
            <Route index element={<CCAssetDetails />} />
            <Route path={ROUTES.AssetDetails} element={<CCAssetDetails />} />
          </Route>
          <Route
            path={`${ROUTES.Workspace}/:accountId/:memberId/${ROUTES.AccountDetails}`}
            element={<MemberDetails />}
          />
          <Route path={ROUTES.Home} element={<Navigate to={ROUTES.Users} />} />
          {/* <Route path={ROUTES.ScanManagement} element={<AccountsTabs title="Scan management" />}>
            <Route index element={<ScanManagementTable />} />
            <Route path={ROUTES.ContentCreators} element={<ScanManagementTable />} />
            <Route path={ROUTES.BusinessAccounts} element={<ScanManagementTable isBusiness />} />
          </Route>
          <Route path={`${ROUTES.ScanManagement}/:type/:id/${ROUTES.Details}`} element={<ScanManagementDetails />} /> */}
          {/* Notification */}
          <Route
            path={`${ROUTES.Notifications}`}
            element={<AllNotification title="Notification" />}
          />
          <Route path={ROUTES.ESign} element={<ESignPage />} />
          <Route path={ROUTES.CopyrightRegistration} element={<CopyrightPage />} />
        </Route>
        {/* Working mode */}
        <Route element={<ProtectedRoute isAuthorized={!!user} noLayout />}>
          <Route path={`${ROUTES.WorkingMode}/:assetId/:id`} element={<WorkingMode />}>
            <Route index element={<WorkingModeRaw />} />
            <Route path={ROUTES.Raw} element={<WorkingModeRaw />} />
            <Route path={ROUTES.Infringing} element={<WorkingModeInfringing />} />
            <Route path={ROUTES.FalsePositive} element={<WorkingModeFalsePositive />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Loader>
  );
};
