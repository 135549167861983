import { FC, useState, useEffect, useCallback } from 'react';
import { Box, Checkbox, IconButton, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import debounce from 'lodash.debounce';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ListItem from '@mui/material/ListItem';

import { SearchIcon } from '@atoms/Icons';

const icon = <CheckBoxOutlineBlankIcon sx={{ width: '12px', height: '12px' }} />;
const checkedIcon = <CheckBoxIcon sx={{ width: '12px', height: '12px' }} />;

// const CustomPopper = (props: any) => (
//   <Popover
//     {...props}
//     modifiers={[
//       {
//         name: 'preventOverflow',
//         options: {
//           boundary: 'viewport',
//         },
//       },
//     ]}
//     style={{
//       width: 'auto', // Allow dynamic width
//       minWidth: props.anchorEl?.offsetWidth || '100%', // Match input width initially
//     }}
//     placement="bottom-start"
//   />
// );
interface TableSearchProps {
  defaultSearch?: string;
  placeholder?: string;
  showFilterBySelect?: boolean;
  filterBySelectOptions?: { value: string; label: string }[];
  onSearch: (search: string, filterBy?: { value: string; label: string }) => void;
}

export const TableSearch: FC<TableSearchProps> = ({
  defaultSearch = '',
  placeholder = 'Search for User name and Email',
  filterBySelectOptions = [],
  showFilterBySelect,
  onSearch,
}) => {
  const [search, setSearch] = useState(defaultSearch);
  const [filterBy, setFilterBy] = useState<{ value: string; label: string } | undefined>();

  // Debounced search to improve performance
  const debouncedSearch = useCallback(
    debounce(
      (_search: string, _filterBy?: { value: string; label: string }) =>
        onSearch(_search, _filterBy),
      300,
    ),
    [],
  );

  useEffect(() => {
    debouncedSearch(search, filterBy);
  }, [search, filterBy, debouncedSearch]);

  return (
    <Box display="flex" alignItems="center" width="100%" border="1px solid #ccc" borderRadius="8px">
      {showFilterBySelect ? (
        <Autocomplete
          // multiple
          options={filterBySelectOptions}
          value={filterBy}
          onChange={(event, newValue) => setFilterBy(newValue)}
          disableClearable
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderOption={(props, option, { selected }) => (
            <ListItem {...props} style={{ padding: 0 }} sx={{ fontSize: '10px', color: '#A3A7B0' }}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                // s  x={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </ListItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="All"
              variant="outlined"
              size="small"
              sx={{
                fontSize: '14px',
                border: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 'none' },
              }}
            />
          )}
          sx={{
            width: '150px',
            position: 'relative',
            fontSize: '14px',
            '&:after': {
              content: '""',
              position: 'absolute',
              top: '15%',
              right: '-8px', // Adjust spacing for the line
              height: '70%',
              width: '0.5px',
              backgroundColor: '#ccc',
            },
            marginRight: '8px',
            '.MuiOutlinedInput-root': {
              '&:hover .MuiOutlinedInput-notchedOutline': { border: 'none' },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none' },
            },
            '.MuiAutocomplete-popupIndicator': {
              color: '#888',
            },
            ':after': {
              borderLeft: '1px solid #ccc',
            },
          }}
          openOnFocus
        />
      ) : null}
      {/* Search input */}
      <Box position="relative" display="flex" alignItems="center" flex="1">
        <Typography component="span" sx={{ position: 'absolute', left: '12px', color: '#ccc' }}>
          <SearchIcon />
        </Typography>
        <TextField
          id="table-search-input"
          value={search}
          onChange={({ target: { value } }) => setSearch(value)}
          placeholder={placeholder}
          variant="outlined"
          size="small"
          fullWidth
          sx={{
            paddingLeft: '36px',
            '.MuiOutlinedInput-notchedOutline': { border: 'none' },
            '&:hover .MuiOutlinedInput-notchedOutline': { border: 'none' },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none' },
            '.MuiOutlinedInput-root': {
              '&:hover .MuiOutlinedInput-notchedOutline': { border: 'none' },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none' },
            },
          }}
        />
        {search && (
          <IconButton
            sx={{ position: 'absolute', right: '12px', color: '#ccc' }}
            onClick={() => setSearch('')}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
