import { Button } from '@atoms';
import { SxProps } from '@mui/system';

interface IInteractButtonProps {
  sx: SxProps;
  text: string;
  variant: 'text' | 'outlined' | 'contained';
  onClick: () => void;
  disabled?: boolean;
}

const InteractButton = ({ sx, text, variant, disabled, onClick }: IInteractButtonProps) => {
  return (
    <Button
      sx={{
        paddingX: '41px',
        paddingY: '10px',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 600,
        maxWidth: '116px',
        borderRadius: '10px',
        maxHeight: '40px',
        ...sx,
      }}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};
export default InteractButton;
