import { CSSProperties } from 'react';
import { createTheme } from '@mui/material';
import type {} from '@mui/x-data-grid/themeAugmentation';

declare module '@mui/material/styles' {
  interface Palette {
    mainBackground: CSSProperties['color'];
    neutral: Palette['primary'];
    black: Palette['primary'];
    disabled: Palette['primary'];
  }
  interface PaletteOptions {
    mainBackground: CSSProperties['color'];
    black: PaletteOptions['primary'];
    disabled: PaletteOptions['primary'];
  }
  interface ThemeOptions {
    status: {
      active: CSSProperties['color'];
      disabled: CSSProperties['color'];
      emailSent: CSSProperties['color'];
      free: CSSProperties['color'];
      pending: CSSProperties['color'];
    };
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    black: true;
    disabled: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    black: true;
    disabled: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    black: true;
    disabled: true;
  }
}

declare module '@mui/material/InputBase' {
  interface InputBasePropsColorOverrides {
    black: true;
    disabled: true;
  }
}

export const theme = createTheme({
  status: {
    active: '#9DD049',
    free: '#9DD049',
    pending: '#9DD049',
    disabled: '#FF4848',
    emailSent: '#44AEFB',
  },
  typography: {
    fontFamily: 'Plus Jakarta Sans',
    h4: {
      fontWeight: 600,
    },
    body2: {
      lineHeight: '20px',
    },
  },
  palette: {
    primary: {
      main: '#44AEFB',
      contrastText: '#fff',
      light: '#DAEFFE',
    },
    secondary: {
      main: 'rgba(0, 0, 0, 0.6)',
    },
    text: {
      primary: '#1D1D1D',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    info: {
      main: '#69BEFC',
      contrastText: '#fff',
    },
    error: {
      main: '#FF4848',
    },
    warning: {
      main: '#F1CE12',
      contrastText: '#ffffff',
    },
    black: {
      main: '#1D1D1D',
      contrastText: '#ffffff',
      dark: '#1D1D1D',
      light: '#1D1D1D',
    },
    disabled: {
      main: '#E0E0E0',
      contrastText: '#ffffff',
      dark: '#1D1D1D',
      light: '#1D1D1D',
    },
    success: {
      main: '#9DD049',
      contrastText: '#ffffff',
    },
    mainBackground: '#f6f6f6',
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',

          '& .MuiInput-underline:after': {
            border: '1px solid rgba(0, 0, 0, 0.38)',
            height: '50px',
          },
          '& .MuiFormHelperText-root': {
            marginLeft: 0,
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderRadius: '8px',
            },
            '&:hover fieldset': {
              border: ' 1px solid rgba(0, 0, 0, 0.38)',
            },
            '&.Mui-focused fieldset': {
              border: ' 1px solid #44AEFB',
            },
            '&.Mui-disabled fieldset': {
              border: ' 1px solid #E0E0E0',
            },
            '& .MuiOutlinedInput-input': {
              borderRadius: '8px',
              fontFamily: 'Plus Jakarta Sans',
              padding: '13px 12px',

              '::placeholder': {
                fontFamily: 'Plus Jakarta Sans',
                color: 'rgba(0, 0, 0, 0.38)',
                opacity: 1,
                fontWeight: 400,
                fontSize: '16px',
              },
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          width: '100%',
          borderRadius: '8px',
          '& fieldset': {
            borderRadius: '8px',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.38)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#44AEFB',
            borderWidth: '1px',
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            border: ' 1px solid #E0E0E0',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '24px',
          marginBottom: '8px',
          color: '#1D1D1D',
          outline: 'none',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          padding: '0 32px',
          textTransform: 'none',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '20px',
          height: '50px',
          borderRadius: '8px',
        },
        text: {
          '&:hover': {
            background: 'none',
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 700,
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
          },
        },
        cellContent: {
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '157%',
          letterSpacing: '0.1px',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #E0E0E0',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: '200px',
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        elevation: 8,
      },
      styleOverrides: {
        list: {
          minWidth: '150px',
        },
        root: {
          '& .MuiPaper-root': {
            marginTop: '4px',
            borderRadius: '8px',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ':hover': {
            background: '#DAEFFE',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          color: 'black',
          borderColor: 'rgba(33, 150, 243, 0.5)',
          padding: '8px',
          minWidth: '120px',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: {
          width: '32px',
          height: '32px',
        },
        colorPrimary: {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          ':disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.12);',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '13px 12px',
          '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.38)',
          },
        },
        icon: {
          '&.Mui-disabled': {
            display: 'none',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: '100%',
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        avatar: {
          width: 30,
          height: 30,
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '143%',
          letterSpacing: '0.15px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderColor: '#1D1D1D80',
          '&.Mui-disabled': {
            opacity: 1,
            borderColor: '#E0E0E0',
            color: 'rgba(0, 0, 0, 0.38)',
          },
        },
        label: {
          padding: '4px 10px',
        },
        deleteIcon: {
          color: '#1D1D1D',
          opacity: '0.7',
          ':hover': {
            color: '#1D1D1D',
            opacity: '0.8',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          whiteSpace: 'pre-wrap',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardError: {
          backgroundColor: 'rgba(255, 72, 72, 0.08)',
          borderRadius: '8px',
          color: '#FF4848',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          height: '49px',
          padding: '0',
          paddingLeft: '6px',
        },
      },
    },
  },
});
