import { useQuery, UseQueryOptions } from 'react-query';
import { Axios as api } from '@helpers';
import { SocialMediaLink } from '@services/types/api';
import QueryKeys from '@constants/query-keys';

export const getSocialLinks = async (): Promise<{ data: SocialMediaLink[] }> => {
  const response = await api.get(`/social-media-links`);
  return response;
};

export const getSocialMediaLinksQueryOptions = (): UseQueryOptions<{ data: SocialMediaLink[] }> => {
  return {
    queryKey: [QueryKeys.socialMediaLinks],
    queryFn: getSocialLinks,
  };
};

type UseUsersOptions = {
  queryConfig?: UseQueryOptions<{ data: SocialMediaLink[] }>;
};

export const useSocialMediaLinks = ({ queryConfig }: UseUsersOptions = {}) => {
  return useQuery({
    ...getSocialMediaLinksQueryOptions(),
    ...queryConfig,
  });
};
