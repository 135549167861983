import { FC } from 'react';

import { BaseIcon } from '../BaseIcon';
import { SvgIconProps } from '@mui/material';

export const EditIcon: FC<SvgIconProps> = (props) => (
  <BaseIcon {...props}>
    <path d="M16.7936 2.11203C16.5512 1.7503 16.2552 1.39495 15.9156 1.05504C15.6822 0.821462 15.3317 0.542446 14.875 0.227985C14.3674 -0.12374 13.6845 -0.0628468 13.2505 0.371583L12.7139 0.908712C13.3459 1.29134 13.9824 1.79302 14.5799 2.39195C15.1782 2.99088 15.6795 3.62889 16.0581 4.26418L16.6229 3.69978C17.0524 3.2699 17.1241 2.6028 16.7936 2.11294V2.11203Z" />
    <path d="M11.8895 1.73303L4.85682 8.77205C4.81687 8.81295 4.78509 8.86112 4.76693 8.91474L3.38764 12.8346C3.31137 13.0527 3.36494 13.2899 3.52839 13.4535C3.69183 13.6171 3.92882 13.6707 4.14584 13.5935L8.06214 12.213C8.11572 12.1948 8.16475 12.163 8.20561 12.123L15.2337 5.08849C14.8832 4.4523 14.3929 3.80247 13.7818 3.18991C13.1789 2.58734 12.5196 2.08475 11.8895 1.73212V1.73303ZM7.51097 11.5995L5.25817 12.3929C5.18734 12.2548 5.09927 12.1202 4.97941 12.0003C4.85955 11.8803 4.72425 11.7912 4.58714 11.7194L5.37984 9.46641L6.04179 9.44187C6.19888 9.43642 6.32782 9.56548 6.32237 9.72271L6.29786 10.4007C6.2915 10.5579 6.42044 10.687 6.57843 10.6815L7.25582 10.6561C7.41291 10.6506 7.54185 10.7797 7.5364 10.9369L7.51188 11.6004L7.51097 11.5995Z" />
    <path d="M13.782 9.04564C13.3961 9.04564 13.0837 9.35828 13.0837 9.74454V14.4506C13.0837 15.0858 12.567 15.603 11.9314 15.603H2.54791C1.9132 15.603 1.39563 15.0858 1.39563 14.4506V5.05761C1.39563 4.42233 1.9123 3.90519 2.54791 3.90519H7.24874C7.63465 3.90519 7.94701 3.59255 7.94701 3.20629C7.94701 2.82003 7.63465 2.50739 7.24874 2.50739H2.54791C1.1432 2.50739 0 3.65072 0 5.05761V14.4506C0 15.8565 1.1432 17.0008 2.54791 17.0008H11.9314C13.3361 17.0008 14.4793 15.8575 14.4793 14.4506V9.74454C14.4793 9.35828 14.1679 9.04564 13.782 9.04564Z" />
    <path d="M13.782 9.04564C13.3961 9.04564 13.0837 9.35828 13.0837 9.74454V14.4506C13.0837 15.0858 12.567 15.603 11.9314 15.603H2.54791C1.9132 15.603 1.39563 15.0858 1.39563 14.4506V5.05761C1.39563 4.42233 1.9123 3.90519 2.54791 3.90519H7.24874C7.63465 3.90519 7.94701 3.59255 7.94701 3.20629C7.94701 2.82003 7.63465 2.50739 7.24874 2.50739H2.54791C1.1432 2.50739 0 3.65072 0 5.05761V14.4506C0 15.8565 1.1432 17.0008 2.54791 17.0008H11.9314C13.3361 17.0008 14.4793 15.8575 14.4793 14.4506V9.74454C14.4793 9.35828 14.1679 9.04564 13.782 9.04564Z" />
  </BaseIcon>
);
