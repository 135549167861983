// let's imagine this file is autogenerated from the backend
// ideally, we want to keep these api related types in sync
// with the backend instead of manually writing them out

export type BaseEntity = {
  id: string;
  createdAt: number;
};

export type Entity<T> = {
  [K in keyof T]: T[K];
} & BaseEntity;

export type Meta = {
  page: number;
  total: number;
  totalPages: number;
};

export type SocialMediaLink = Entity<{
  platform: SocialMediaPlatform;
  modelId: string;
  accountUrl: string;
  mainUrl: string;
  thumbnail: string;
  postType: PostType;
  accountName: string;
  category: SocialMediaCategory;
  caseState: CaseState;
  complianceStatus: ComplianceStatus;
  lastEnforcementDate: string;
  detectionDate: string;
  page: string;
}>;

export enum ComplianceStatus {
  Compliant = 'compliant',
  NonCompliant = 'non_compliant',
}

export const complianceStatusMapper = {
  [ComplianceStatus.Compliant]: 'Compliant',
  [ComplianceStatus.NonCompliant]: 'Non Compliant',
};

export enum CaseState {
  Open = 'open',
  Closed = 'closed',
  Enforced = 'enforced',
}

export const caseStateMapper = {
  [CaseState.Open]: 'Open',
  [CaseState.Closed]: 'Closed',
  [CaseState.Enforced]: 'Enforced',
};

export enum PostType {
  Page = 'page',
  Post = 'post',
  PrivateAccount = 'private_account',
  Video = 'video',
  Stream = 'stream',
  Advertisement = 'advertisement',
  Listing = 'listing',
}

export const postTypeMapper = {
  [PostType.Page]: 'Page',
  [PostType.Post]: 'Post',
  [PostType.PrivateAccount]: 'Private Account',
  [PostType.Video]: 'Video',
  [PostType.Stream]: 'Stream',
  [PostType.Advertisement]: 'Advertisement',
  [PostType.Listing]: 'Listing',
};

export enum SocialMediaCategory {
  Impersonation = 'impersonation',
  SuspectedCounterfeit = 'suspected_counterfeit',
  CopyrightViolation = 'copyright_violation',
  TrademarkViolation = 'trademark_violation',
  ClientOwned = 'client_owned',
  Affiliate = 'affiliate',
}

export const socialMediaCategoryMapper = {
  [SocialMediaCategory.Impersonation]: 'Impersonation',
  [SocialMediaCategory.SuspectedCounterfeit]: 'Suspected Counterfeit',
  [SocialMediaCategory.CopyrightViolation]: 'Copyright Violation',
  [SocialMediaCategory.TrademarkViolation]: 'Trademark Violation',
  [SocialMediaCategory.ClientOwned]: 'Client Owned',
  [SocialMediaCategory.Affiliate]: 'Affiliate',
};

export enum SocialMediaPlatform {
  DAILYMOTION = 'dailymotion',
  DOUYIN = 'douyin',
  FACEBOOK = 'facebook',
  FACEBOOK_ADS = 'facebook_ads',
  FACEBOOK_ADS_LIBRARY = 'facebook_ads_library',
  FIVERR = 'fiverr',
  IMGUR = 'imgur',
  INSTAGRAM = 'instagram',
  INSTAGRAM_ADS = 'instagram_ads',
  INSTAGRAM_ADS_LIBRARY = 'instagram_ads_library',
  KAKAO_STORY = 'kakao_story',
  KAKAO_TALK = 'kakao_talk',
  LINKEDIN = 'linkedin',
  NAVER_BLOG = 'naver_blog',
  OK = 'ok',
  PATREON = 'patreon',
  PINTEREST = 'pinterest',
  QQ = 'qq',
  REDDIT = 'reddit',
  TELEGRAM = 'telegram',
  TELEGRAM_CONTENT = 'telegram_content',
  TIKTOK = 'tiktok',
  TUMBLR = 'tumblr',
  TWITCH = 'twitch',
  TWITTER = 'twitter',
  VK = 'vk',
  WECHAT = 'wechat',
  WEIBO = 'weibo',
  WEIXIN_SOGOU = 'weixin_sogou',
  WHATSAPP = 'whatsapp',
  XIAOHONGSHU = 'xiaohongshu',
  YOUTUBE = 'youtube',
  YUPOO = 'yupoo',
  ZHIHU = 'zhihu',
}

export const platformMapper = {
  [SocialMediaPlatform.DAILYMOTION]: 'Dailymotion',
  [SocialMediaPlatform.DOUYIN]: 'Douyin',
  [SocialMediaPlatform.FACEBOOK]: 'Facebook',
  [SocialMediaPlatform.FACEBOOK_ADS]: 'Facebook Ads',
  [SocialMediaPlatform.FACEBOOK_ADS_LIBRARY]: 'Facebook Ads Library',
  [SocialMediaPlatform.FIVERR]: 'Fiverr',
  [SocialMediaPlatform.IMGUR]: 'Imgur',
  [SocialMediaPlatform.INSTAGRAM]: 'Instagram',
  [SocialMediaPlatform.INSTAGRAM_ADS]: 'Instagram Ads',
  [SocialMediaPlatform.INSTAGRAM_ADS_LIBRARY]: 'Instagram Ads Library',
  [SocialMediaPlatform.KAKAO_STORY]: 'Kakao Story',
  [SocialMediaPlatform.KAKAO_TALK]: 'Kakao Talk',
  [SocialMediaPlatform.LINKEDIN]: 'LinkedIn',
  [SocialMediaPlatform.NAVER_BLOG]: 'Naver Blog',
  [SocialMediaPlatform.OK]: 'OK',
  [SocialMediaPlatform.PATREON]: 'Patreon',
  [SocialMediaPlatform.PINTEREST]: 'Pinterest',
  [SocialMediaPlatform.QQ]: 'QQ',
  [SocialMediaPlatform.REDDIT]: 'Reddit',
  [SocialMediaPlatform.TELEGRAM]: 'Telegram',
  [SocialMediaPlatform.TELEGRAM_CONTENT]: 'Telegram Content',
  [SocialMediaPlatform.TIKTOK]: 'TikTok',
  [SocialMediaPlatform.TUMBLR]: 'Tumblr',
  [SocialMediaPlatform.TWITCH]: 'Twitch',
  [SocialMediaPlatform.TWITTER]: 'Twitter',
  [SocialMediaPlatform.VK]: 'VK',
  [SocialMediaPlatform.WECHAT]: 'WeChat',
  [SocialMediaPlatform.WEIBO]: 'Weibo',
  [SocialMediaPlatform.WEIXIN_SOGOU]: 'Weixin Sogou',
  [SocialMediaPlatform.WHATSAPP]: 'WhatsApp',
  [SocialMediaPlatform.XIAOHONGSHU]: 'Xiaohongshu',
  [SocialMediaPlatform.YOUTUBE]: 'YouTube',
  [SocialMediaPlatform.YUPOO]: 'Yupoo',
  [SocialMediaPlatform.ZHIHU]: 'Zhihu',
};
