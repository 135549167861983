import { FC } from 'react';
import { SelectProps } from '@mui/material';

import { LinkStatuses } from '@services';
import { LINK_STATUS_CONTENT } from '@constants';

import { SelectStyled, MenuItemStyled } from '@molecules/Filters/styles';
import { FilterCloseWrap } from '@molecules/Filters/FilterCloseWrap';

export const CaseStateFilter: FC<{ onClose?: () => void } & SelectProps> = ({
  onClose,
  ...props
}) => (
  <FilterCloseWrap label="Status" onClose={onClose}>
    <SelectStyled placeholder="Select option" {...props}>
      {Object.entries(LinkStatuses).map(([, key]) => (
        <MenuItemStyled key={key} value={key} color={LINK_STATUS_CONTENT[key].color}>
          {LINK_STATUS_CONTENT[key].label}
        </MenuItemStyled>
      ))}
    </SelectStyled>
  </FilterCloseWrap>
);
