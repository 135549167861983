import { QueryClient } from 'react-query';

import { errorMessage } from '@services';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retryDelay: 3000,
      retry: 1,
      cacheTime: 0,
      onError: (e) => {
        const error = e as Error;

        if (error?.message) errorMessage(String(error?.message));

        return error;
      },
    },
  },
});

import { UseMutationOptions } from 'react-query';

export type ApiFnReturnType<FnType extends (...args: any) => Promise<any>> = Awaited<
  ReturnType<FnType>
>;

export type QueryConfig<T extends (...args: any[]) => any> = Omit<
  ReturnType<T>,
  'queryKey' | 'queryFn'
>;

export type MutationConfig<MutationFnType extends (...args: any) => Promise<any>> =
  UseMutationOptions<ApiFnReturnType<MutationFnType>, Error, Parameters<MutationFnType>[0]>;
