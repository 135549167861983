import { useMutation, useQueryClient } from 'react-query';

import { MutationConfig } from '@helpers/query-client';
import * as yup from 'yup';
import { InferType } from 'yup';
import { Axios } from '@helpers';
import { getSocialMediaLinksQueryOptions } from './get-social-links';

export const createSocialMediaLinkInputSchema = yup.object({
  platform: yup.string().required('Platform is required'),
  accountUrl: yup.string().url('Must be a valid URL').required('Page/Account URL is required'),
  mainUrl: yup.string().url('Must be a valid URL').notRequired(),
  accountName: yup.string().required('Account Name is required'),
  category: yup.string().required('Category is required'),
  postType: yup.string().required('Post Type is required'),
  caseState: yup.string().required('Case State is required'),
});

export type CreateSocialMediaLinkInput = InferType<typeof createSocialMediaLinkInputSchema>;

export const createSocialMediaLink = ({
  data,
  modelId,
}: {
  data: { links: CreateSocialMediaLinkInput[] };
  modelId: string;
}) => {
  return Axios.post(`/admin/models/${modelId}/social-media-links`, data);
};

type UseCreateSocialMediaLinksOptions = {
  mutationConfig?: MutationConfig<typeof createSocialMediaLink>;
};

export const useCreateSocialMediaLinks = ({
  mutationConfig,
}: UseCreateSocialMediaLinksOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: getSocialMediaLinksQueryOptions().queryKey,
      });
      onSuccess?.(...args);
    },
    ...restConfig,
    mutationFn: createSocialMediaLink,
  });
};
