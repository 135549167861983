import * as yup from 'yup';
import { InferType } from 'yup';
import { Axios, MutationConfig } from '@helpers';
import { useMutation, useQueryClient } from 'react-query';
import { getSocialMediaLinksQueryOptions } from './get-social-links';

export const editMultipleSocialMediaLinkInputSchema = yup.object({
  category: yup.string().nullable().required('Category is required'),
  postType: yup.string().nullable().required('Post Type is required'),
  caseState: yup.string().nullable().required('Case State is required'),
  complianceStatus: yup.string().nullable().required('Compliance Status is required'),
});

export type EditMultipleSocialMediaLinkInput = InferType<
  typeof editMultipleSocialMediaLinkInputSchema
>;

export const editMultipleSocialMediaLink = ({
  data,
  modelId,
}: {
  data: EditMultipleSocialMediaLinkInput;
  modelId: string;
}) => {
  return Axios.patch(`/admin/models/${modelId}/social-media-links`, data);
};

type UseEditMultipleSocialMediaLinksOptions = {
  mutationConfig?: MutationConfig<typeof editMultipleSocialMediaLink>;
};

export const useEditMultipleSocialMediaLinks = ({
  mutationConfig,
}: UseEditMultipleSocialMediaLinksOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: getSocialMediaLinksQueryOptions().queryKey,
      });
      onSuccess?.(...args);
    },
    ...restConfig,
    mutationFn: editMultipleSocialMediaLink,
  });
};
