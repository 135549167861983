import { SocialLinksFilterProvider } from '@contexts/social-media-links/SocialLinksProvider';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
// import SocialMediaFilter from 'src/features/SocialMediaLinks/components/social-media-filter';
import SocialMediaList from 'src/features/SocialMediaLinks/components/social-media-list';
import SocialUserInfo from 'src/features/SocialMediaLinks/components/social-user-info';

const SocialMedia = () => {
  const { id, assetId } = useParams();
  console.log(id, assetId);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <SocialUserInfo />
      <SocialLinksFilterProvider>
        {/* <SocialMediaFilter /> */}
        <SocialMediaList />
      </SocialLinksFilterProvider>
    </Box>
  );
};

export default SocialMedia;
