import * as yup from 'yup';
import { InferType } from 'yup';
import { Axios, MutationConfig } from '@helpers';
import { useMutation, useQueryClient } from 'react-query';
import { getSocialMediaLinksQueryOptions } from './get-social-links';

export const editSocialMediaLinkInputSchema = yup.object({
  accountName: yup.string().required('Account Name is required'),
  category: yup.string().required('Category is required'),
  postType: yup.string().required('Post Type is required'),
  caseState: yup.string().required('Case State is required'),
  complianceStatus: yup.string().required('Compliance Status is required'),
});

export type EditSocialMediaLinkInput = InferType<typeof editSocialMediaLinkInputSchema>;

export const editSocialMediaLink = ({
  data,
  modelId,
  id,
}: {
  data: EditSocialMediaLinkInput;
  modelId: string;
  id: string;
}) => {
  return Axios.put(`/admin/models/${modelId}/social-media-links/${id}`, data);
};

type UseEditSocialMediaLinksOptions = {
  mutationConfig?: MutationConfig<typeof editSocialMediaLink>;
};

export const useEditSocialMediaLinks = ({
  mutationConfig,
}: UseEditSocialMediaLinksOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: getSocialMediaLinksQueryOptions().queryKey,
      });
      onSuccess?.(...args);
    },
    ...restConfig,
    mutationFn: editSocialMediaLink,
  });
};
