import { Typography } from '@mui/material';
import { CaseState, caseStateMapper } from '@services/types/api';

interface Props {
  state: CaseState;
}
const CaseStateCell = ({ state }: Props) => {
  return (
    <Typography fontSize="small" color={state === CaseState.Closed ? '#D4D5D6' : undefined}>
      {caseStateMapper[state]}
    </Typography>
  );
};

export default CaseStateCell;
