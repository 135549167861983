import { Button } from '@atoms';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const UploadButton = () => {
  return (
    <Button
      sx={{ paddingX: '20px' }}
      variant="outlined"
      color="black"
      startIcon={<FileUploadIcon />}
    >
      Upload
    </Button>
  );
};
export default UploadButton;
