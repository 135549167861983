import { Box, FormHelperText, styled } from '@mui/material';

export const defaultFormControlStyle = {
  backgroundColor: '#fff',
  borderRadius: '10px',
};

export const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  color: 'red',
  marginTop: theme.spacing(0.5),
  height: '20px',
  position: 'absolute',
  bottom: '-20px',
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(0.5),
}));
