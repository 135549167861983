import { FC } from 'react';

import { BaseIcon } from '../BaseIcon';
import { SvgIconProps } from '@mui/material';

export const DeleteIcon: FC<SvgIconProps> = (props) => (
  <BaseIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.16748 0.000396729H5.83415V1.66706H1.66748V3.33373H13.3341V1.66706H9.16748V0.000396729ZM11.6675 15.0004L12.5008 5.0004H2.50081L3.33415 15.0004H11.6675Z"
    />
  </BaseIcon>
);
