import { Dispatch, ReactElement, ReactNode } from 'react';
import { GridSortDirection } from '@mui/x-data-grid';
import { SelectChangeEvent } from '@mui/material';
import { CopyrightContentStatus } from './types/copyright';

export * from './types/asset-details';

export type OptionType = {
  value: string;
  label: string;
  icon?: ReactElement | ReactNode;
};

export interface Queries {
  page: number;
  size: number;
  sortBy: string;
  sortDirection: GridSortDirection;
  filterText: string;
  sourceType?: string;
}

export enum ActiveStatus {
  EmailSent = 'emailSent',
  Active = 'active',
  Disabled = 'disabled',
  Free = 'free',
  Pending = 'pending',
  Overdue = 'overdue',
}

export enum CreatorPaymentStatus {
  Deleted = 'deleted',
  ActiveFree = 'activeFree',
  ActivePaid = 'activePaid',
  Trial = 'trial',
  Canceled = 'canceled',
  EmailSent = 'emailSent',
  Overdue = 'overdue',
  Paused = 'paused',
}

export enum PaymentStatus {
  EmailSent = 'emailSent',
  Active = 'active',
  Overdue = 'overdue',
  Canceled = 'canceled',
  Paused = 'paused',
}

export enum Plans {
  Free = 'free',
  Star = 'star',
  Elite = 'elite',
  Vip = 'vip',
  Business = 'business',
  Agency = 'agencies',
  Enterprise = 'enterprise',
  Platinum = 'platinum',
}

export enum CompanyType {
  ContentCreator = 'contentCreator',
  Business = 'business',
}

export enum AssignAssetType {
  ContentCreator = 'contentCreator',
  Business = 'business',
  Model = 'model',
}

export type MoveAssetParams = {
  modelIds: string[];
};

export type AssignAssetsParams = {
  companyIds?: string[];
  modelIds?: string[];
};

export interface User {
  email: string;
  firstName: string;
  id: string;
  modelId: string;
  lastName: string;
  role: string;
  status: ActiveStatus;
  invitedAt?: Date;
  userCompanyRole: string;
  isOnboarded: boolean;
  companyType: CompanyType;
  myCompanyId: string;
  avatarUrl: string;
}

export interface UserDetailsType {
  avatar: null | string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  role: UserRole;
  status: ActiveStatus;
}

export enum UserRole {
  Admin = 'admin',
  Manager = 'manager',
}

export interface AssignedAccount {
  id: string;
  logo: null | string;
  name: string;
}

export interface TableUser {
  avatar: null | string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  role: UserRole;
  status: ActiveStatus;
  assignedAccounts: AssignedAccount[];
  invitedAt?: string;
}

export enum LinkStatuses {
  Raw = 'raw',
  Infringing = 'infringing',
  FalsePositive = 'falsePositive',
  Submitted = 'submitted',
  Invalid = 'invalid',
}

export enum SourceType {
  GoogleWeb = 'google_web',
  GoogleImages = 'google_imgs',
  AtSource = 'at_source',
}

export enum LinkSourceType {
  All = 'all',
  GoogleWeb = 'googleWeb',
  GoogleImages = 'googleImages',
  AtSource = 'atSource',
  SubmittedManually = 'submittedManually',
}

export enum LinkAtSourceInformationAiMatches {
  PeopleFound = 'people_found',
  Nsfw = 'NSFW',
  PersonMatched = 'person_matched',
  ObjectOfInterest = 'object_of_interest',
  VideoStreamFound = 'video_stream_found',
  InfringingMatch = 'infringing_match',
}

export type SelectLinkStatuses = LinkStatuses | null | 'mixed';
export type SelectCopyrightStatuses = CopyrightContentStatus | null | 'mixed';

export type FiltersQuery = Record<string, string | null>;

export interface FilterItem {
  name: string;
  key: string;
  resetFields?: string[];
  getFilter: (
    onChange: ({ target: { value, name } }: SelectChangeEvent<unknown>) => void,
    onClose: () => void,
    setFilters: Dispatch<FiltersQuery>,
    filters: FiltersQuery,
  ) => ReactNode;
}

export interface DocumentType {
  name: string;
  type: string;
  id: string;
  url: string;
}

export interface LinkType {
  addedType: 'fromScratch' | 'submittedManually';
  createdAt: string;
  domain: string;
  id: string;
  modelId: string;
  status: LinkStatuses;
  updatedAt: string;
  url: string;
  sourceType?: SourceType;
  webpageStats: object | null;
}

export interface LinkManagementType {
  addedType: string;
  createdAt: string;
  domain: string | null;
  id: string | string[];
  modelId: string;
  status: string;
  updatedAt: string;
  url: string;
  sourceType?: SourceType | string;
}

export type SocialMediaType = {
  fullLink: string;
  nickname: string;
};

export type PaymentInfoType = {
  nextPayment?: {
    date: string;
    amount: number;
    currency: string;
  };
  lastPayment?: {
    date: string;
    amount: number;
  };
  quantity: number;
  updateUrl: string;
  planId: string;
};

export interface CCBaseDetailsType {
  companyName: string;
  companyType: CompanyType;
  email: string;
  firstName: string;
  id: string;
  isRemoved: boolean;
  joinDate: string;
  lastModified: string;
  lastName: string;
  logo: string;
  overrideFormulaCoef: null | number;
  plan: Plans;
  status: PaymentStatus;
  transparencyReportLink?: string;
  canStartScan: boolean;
  isBlocked?: boolean;
}

export interface CCModelDetailsType extends CCBaseDetailsType {
  nextBillingDate: string;
  modelId: string;
  socialMedia: SocialMediaType[];
  freeTrialEffectiveDate?: string;
  subscriptionCancellationEffectiveDate?: string;
  billingFrequency?: string;
}

export interface CCAssetDetailsType extends CCBaseDetailsType {
  paymentInfo?: PaymentInfoType;
  assetType: AssetsType;
  slots: {
    available: number;
    used: number;
  };
  freeTrialEffectiveDate?: string;
}

export interface ModelType {
  username: string;
  fullName: string;
  id: string;
  joinDate: string;
  lastModified: string;
  links: {
    found: number;
    deleted: number;
  };
  isRemoved: boolean;
  photo_url: string;
  company: {
    name: string;
    id: string;
  };
}

export interface BusinessDetailsType {
  companyName: string;
  firstName: string;
  lastName: string;
  logo: string;
  companyType: CompanyType;
  assetType: AssetsType;
  email: string;
  id: string;
  joinDate: string;
  lastModified: string;
  price: number;
  plan: Plans;
  slots: {
    available: number;
    used: number;
  };
  paymentInfo?: PaymentInfoType;
  planInformation?: {
    id: number;
    billingPeriod: number;
    recurringPrice: { USD: string };
    quantity: number;
    name: Plans;
  };
  nextPlanInformation?: {
    id: number;
    billingPeriod: number;
    name: Plans;
    quantity: number;
    recurringPrice: { USD: string };
  };
  isRemoved: boolean;
  status: PaymentStatus;
  nextBillingDate: string;
  overrideFormulaCoef: null | number;
  isBlocked?: boolean;
  slotsCount: number | string;
}

export interface AssetDetailsType {
  id: string;
  photoUrl: string;
  transparencyReportLink: string | null;
  updatedAt: string;
  username: string;
  assetType: AssetsType;
  properties: { [x: string]: unknown };
  canStartScan: boolean;
  plan?: string;
  scanSettings?: {
    updatedAt: string;
  };
}

export type BackendError = {
  code: number;
  message: string;
  error: string;
  response?: {
    data: {
      code: number;
      error: string;
      errorId: string;
      messages: string | string[];
    };
  };
};

export enum MemberStatus {
  EmailSent = 'emailSent',
  Active = 'active',
  Blocked = 'disabled',
}

export enum MemberRole {
  CompanyAdmin = 'companyAdmin',
  CompanyManager = 'companyManager',
  CompanyInfluencer = 'companyInfluencer',
}

export interface ManagedAccount {
  username: string;
  photoUrl: string;
  isRemoved?: boolean;
  joinDate: string;
  id: string;
}

export interface MemberType {
  companyId: string;
  companyName: string;
  createdAt: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  role: MemberRole;
  status: MemberStatus;
  managedAccounts?: ManagedAccount[];
}

export type NotificationType = {
  createdAt: string;
  id: string;
  isRead: boolean;
  text: string;
  updatedAt: string;
  redirect?: NotificationRedirect;
  modelUsername: string;
};

export type NotificationResponseType = {
  items: NotificationType[] | [];
  size?: number;
  page?: number;
  totalCount: number;
};

export type NotificationRedirect = {
  type?: string;
  payload: {
    companyId?: string;
    assetId?: string;
    assetType?: string;
    type?: string;
  };
};

export type NotificationRedirectType = {
  Cancellations: string[];
  NewSignUps: string[];
  ChangePlans: string[];
  SubmittedLinks: string[];
  Accounts: string[];
};

export type UserActivityType = {
  id: string;
  text: string;
  type?: string;
  isRead?: boolean;
  createdAt: string;
  updatedAt: string;
  modelUsername: string;
};

export type UserActivityResponseType = {
  items: UserActivityType[] | [];
  size?: number;
  page?: number;
  totalCount: number;
};

export type ManagerType = {
  firstName: string;
  lastName: string;
  avatarUrl: string;
};

export interface TableContentCreator {
  assetType: AssetsType;
  contentCreator: string;
  email: string;
  fullName: string;
  id: string;
  isRemoved: boolean;
  joinDate: string;
  lastModified: string;
  lastScanningDate: string;
  links: {
    found: number;
    deleted: number;
  };
  manager: ManagerType | null;
  modelId: string;
  plan: Plans;
  status: PaymentStatus;
  logo: string;
  freeTrialEffectiveDate?: string;
}

export interface TableBusinessAccount {
  companyName: string;
  email: string;
  id: string;
  isRemoved: boolean;
  joinDate: string;
  lastModified: string;
  lastScanningDate: string;
  links: { found: number; deleted: number };
  manager: ManagerType | null;
  plan: Plans;
  slots: { available: number; used: number };
  status: PaymentStatus;
  logo: string;
  freeTrialEffectiveDate?: string;
}

export interface TableModelAccount {
  id: string;
  email: string;
  logo: string;
  username: string;
  isRemoved: boolean;
  joinDate: string;
  manager: ManagerType | null;
}

export type MenuAction = {
  name: string;
  onClick: (props: unknown) => unknown;
};

export enum TableSelectType {
  Link = 'link',
  Account = 'account',
  Assign = 'assign',
  ChangeStatus = 'changeStatus',
  Document = 'document',
  Invoices = 'invoices',
  Copyright = 'copyright',
  SocialMediaLink = 'social-media-link',
}

export interface GeneralLinkInfoData {
  delistedFromGoogle: number;
  delistedFromGooglePercentage: number;
  lastScanningDate: string;
  overrides: { [year in string]: { [month in string]: { atSource: number; googleWeb: number } } };
  removedFromSource: number;
  removedFromSourcePercentage: number;
  totalMedia: number;
  totalMediaPercentage: number;
  value: number;
  valuePercentage: number;
}

export enum AssignStatusType {
  Assign = 'assign',
  Unassign = 'unassign',
}

export enum AssetsType {
  Books = 'books',
  Games = 'games',
  Movies = 'movies',
  Software = 'software',
  TV = 'tv',
  Music = 'music',
  Models = 'models',
  NFT = 'nft',
  Talents = 'talents',
  TVPLE = 'tvple',
  Merchandise = 'merchandise',
}

export enum ScanIdentifierType {
  Business = 'businessSubscriptionPlan',
  ContentCreator = 'ccSubscriptionPlan',
}

export enum ScanSourcesType {
  GoogleWeb = 'googleWeb',
  GoogleImages = 'googleImages',
  AtSource = 'atSource',
  ReverseImageSearch = 'reverseImageSearch',
}

export enum ScanSourcesTypeWithoutReverseImageSearch {
  GoogleWeb = 'googleWeb',
  GoogleImages = 'googleImages',
  AtSource = 'atSource',
}

export interface ScanManagementType {
  id: string;
  identifierValue: Plans | AssetsType;
  identifierType: ScanIdentifierType;
  keywords: string[];
  pages: number;
  scanFrequencyDays: number;
  scanSources: ScanSourcesType[];
  lastScanAt: Date;
  nextScanAt: Date;
}

export interface ScanHistoryItemResponse {
  id: string;
  scannedAt: Date;
  googleWeb: number;
  googleImages: number;
  atSource: number;
  runType: 'automated' | 'manual';
  status: 'success' | 'fail';
  publicityLevel: 'general' | 'personal' | null;
  usernamesUsed: string[];
  keywordsUsed: string[];
  pagesScanned: number;
  scanSources: ScanSourcesType[];
}

export interface ScanSettingsAiFeatures {
  faceMatch: boolean;
  watermark: boolean;
  objectDetect: boolean;
  ocrDetect: boolean;
  people: boolean;
  nsfw: boolean;
}

export enum ScanSettingsAiFeaturesType {
  FaceMatch = 'faceMatch',
  Watermark = 'watermark',
  ObjectDetect = 'objectDetect',
  OcrDetect = 'ocrDetect',
  People = 'people',
  Nsfw = 'nsfw',
}

export enum ImageType {
  WATERMARK = 'watermark',
  REVERSE_IMAGE_SEARCH = 'reverseImageSearch',
}

export interface ScanSettingItemType {
  filterNames: string[];
  filterRemovals: string[];
  id: string;
  updatedAt: string;
  keywords: string[];
  lastScanAt: string;
  pages: number;
  scanFrequencyDays: number;
  scanSources: string[];
  usernames: { username: string; canRemove: boolean }[];
  generalScanEnabled: boolean;
  personalScanEnabled: boolean;
  aiFeatures?: ScanSettingsAiFeatures;
  images: any[];
}

export interface ManagedAccountType {
  assetType: AssetsType;
  companyName: string;
  companyType: CompanyType;
  email: string;
  id: string;
  modelId: string | null;
  isRemoved: boolean;
  joinDate: string;
  lastModified: string;
  lastScanningDate: string | null;
  links: { found: number; deleted: number };
  logo: null | string;
  plan: Plans;
  status: PaymentStatus;
  freeTrialEffectiveDate?: string;
}

export type WhitelistCompanyType = {
  id: string;
  logo: string | null;
  name: string;
};

export interface WhitelistType {
  id: string;
  domainRegex: string;
  uploadedBy: {
    id: string;
    userId: string;
    companyId: string;
    role: MemberRole;
    photoUrl: string | null;
    firstName: string;
    lastName: string;
  };
  createdAt: string;
  updatedAt: string;
  email: string;
  companies: WhitelistCompanyType[];
}

export type TableHistory = {
  id: string;
  fullName: string;
  email: string;
  logo: string;
  modifiedDate: string;
  summaryChanges: string;
};

export interface SocialMediaLinksTable {
  platform: string;
  page: string;
  mainUrl: string;
  accountName: string;
  category: string;
  caseState: string;
  postType: string;
  complianceStatus: string;
  enforcementDate: string;
}
