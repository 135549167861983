import React from 'react';
import { SHORT_DATE_FORMAT } from '@constants/date';
import { Typography, Box, Avatar } from '@mui/material';
import { platformMapper, SocialMediaPlatform } from '@services/types/api';
import dayjs from 'dayjs';
import defaultPlatformThumbnail from '@assets/social-media/platform-thumbnails/default_platform.svg';

interface Props {
  platform: SocialMediaPlatform;
  date?: number | string;
}

export const getPlatformIconPath = async (platform: SocialMediaPlatform): Promise<string> => {
  try {
    const icon = await import(
      `@assets/social-media/platform-thumbnails/${platform}.${
        platform === SocialMediaPlatform.INSTAGRAM_ADS ? 'png' : 'svg'
      }`
    );
    return icon.default;
  } catch (error) {
    console.warn(`Icon for platform "${platform}" not found.`);
    const fallbackIcon = await import(
      '@assets/social-media/platform-thumbnails/default_platform.svg'
    );
    return fallbackIcon.default;
  }
};

const PlatformInfoCell = ({ platform, date }: Props) => {
  const [icon, setIcon] = React.useState<string>();

  React.useEffect(() => {
    getPlatformIconPath(platform).then((icon) => setIcon(icon));
  }, [platform]);

  return (
    <Box display="flex" alignItems="center" width="100%" gap="5px">
      <Avatar
        alt="platform"
        sx={{ width: 23, height: 23 }}
        src={icon || defaultPlatformThumbnail}
      ></Avatar>

      <Box className="ellipsis">
        <Typography variant="body2" className="ellipsis">
          {platformMapper[platform]}
        </Typography>
        {date && (
          <Typography variant="caption" color="text.disabled">
            {dayjs(date).format(SHORT_DATE_FORMAT)}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default React.memo(PlatformInfoCell);
