import { Box, Chip, IconButton, Typography } from '@mui/material';
import React from 'react';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

const SocialUserInfo = () => {
  const chips = ['Content Creator', 'Name'];

  return (
    <Box sx={{ display: 'flex', gap: 2.5, flexWrap: 'wrap', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', gap: 1.25, flexWrap: 'wrap' }}>
        {chips.map((chip) => (
          <Chip key={chip} label={chip} variant="outlined" />
        ))}
      </Box>
      <Box sx={{ display: 'flex', gap: 0.25, flexWrap: 'wrap', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', gap: 1.25, flexWrap: 'wrap', alignItems: 'center' }}>
          <DescriptionOutlinedIcon />
          <Typography variant="body2" color="text.primary">
            Transparency Report:{' '}
            <Typography component="span" variant="body2" color="text.primary" fontWeight={600}>
              Adrian Eve
            </Typography>
          </Typography>
          <IconButton size="small" sx={{ width: '16px', height: '16px' }}>
            <OpenInNewOutlinedIcon color="primary" sx={{ width: '11px', height: '11px' }} />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', gap: 1.25, flexWrap: 'wrap', alignItems: 'center' }}>
          <PersonOutlineOutlinedIcon />
          <Box sx={{ display: 'inline' }}>
            <Typography variant="body2" color="text.primary">
              Default Social Media:{' '}
              <Typography component="span" variant="body2" color="primary">
                onlyfans.com/Adrianna-Eves
              </Typography>
            </Typography>
          </Box>
          <Chip label="Onlyfans" color="primary" />
        </Box>
        <Box sx={{ display: 'flex', gap: 1.25, flexWrap: 'wrap', alignItems: 'center' }}>
          <SmsOutlinedIcon />
          <Typography variant="body2" color="text.primary">
            Social Media Data:
          </Typography>
          <Chip label="Social Media" color="primary" />
        </Box>
      </Box>
    </Box>
  );
};
export default React.memo(SocialUserInfo);
