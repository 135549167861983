import React from 'react';
import { Button, Modal, TextField } from '@atoms';
import AddIcon from '@mui/icons-material/Add';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// import { useMutation } from 'react-query';
import { Autocomplete, Box, FormControl, Grid, TextField as MuiTextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  CaseState,
  caseStateMapper,
  ComplianceStatus,
  platformMapper,
  PostType,
  postTypeMapper,
  SocialMediaCategory,
  socialMediaCategoryMapper,
  SocialMediaPlatform,
} from '@services/types/api';
import InputLabel from '@mui/material/InputLabel';

import {
  createSocialMediaLinkInputSchema,
  CreateSocialMediaLinkInput,
  useCreateSocialMediaLinks,
} from '../api/create-social-links';
import { errorMessage, successMessage } from '@services';
import { useParams } from 'react-router-dom';

const AddSocialMediaLink = () => {
  const [open, setOpen] = React.useState(false);
  const createSocialMediaLinksMutation = useCreateSocialMediaLinks({
    mutationConfig: {
      onSuccess: (data) => {
        if (!data.data?.savedLinks?.length && data.data?.duplicatedLinksCount) {
          errorMessage('Duplicate Social Media Link');
        } else {
          successMessage('Social Media Link has been added!');
          onClose();
        }
      },
      onError: () => {
        errorMessage('Failed to add Social Media Link');
      },
    },
  });

  const { assetId } = useParams();

  const onSubmit = (data: CreateSocialMediaLinkInput) => {
    const payload = {
      ...data,
      // TODO: compliance status is not in the form and should be added
      complianceStatus: ComplianceStatus.Compliant,
      // TODO: enforcement Date is not in the form and should be added
      lastEnforcementDate: new Date().toISOString(),
    };
    if (!assetId) {
      errorMessage('Model could not be found');
      return;
    }
    createSocialMediaLinksMutation.mutate({ data: { links: [payload] }, modelId: assetId });
  };

  const { handleSubmit, control, reset } = useForm<CreateSocialMediaLinkInput>({
    resolver: yupResolver(createSocialMediaLinkInputSchema),
    mode: 'onChange',
    defaultValues: {
      platform: SocialMediaPlatform.DAILYMOTION,
      accountUrl: '',
      mainUrl: '',
      accountName: '',
      category: SocialMediaCategory.ClientOwned,
      postType: PostType.Page,
      caseState: CaseState.Open,
    },
  });

  const onClose = () => {
    reset();
    setOpen(false);
  };
  const platformOptions = Object.values(SocialMediaPlatform);
  const categoryOptions = Object.values(SocialMediaCategory);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        sx={{ paddingX: '30px' }}
        variant="outlined"
        color="black"
        startIcon={<AddIcon />}
      >
        Add
      </Button>
      <Modal title="Add New Results" onClose={onClose} open={open}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Platform */}
          <Grid container spacing={3} my={2}>
            <Grid item xs={12} sm={6}>
              <InputLabel>Platform</InputLabel>
              <FormControl fullWidth sx={{ display: 'flex', flexDirection: 'column' }}>
                <Controller
                  name="platform"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={platformOptions}
                      getOptionLabel={(option) =>
                        platformMapper[option as SocialMediaPlatform] || (option as string)
                      }
                      onChange={(_, value) => field.onChange(value)} // To update the value in the form
                      renderInput={(params) => <MuiTextField {...params} />}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            {/* Page/Account URL */}
            <Grid item xs={12} sm={6}>
              <TextField
                name="accountUrl"
                control={control}
                defaultValue=""
                fullWidth
                label="Page/Account URL"
                placeholder="Insert URL Here"
              />
            </Grid>

            {/* Main URL */}
            <Grid item xs={12} sm={6}>
              <TextField
                name="mainUrl"
                control={control}
                defaultValue=""
                fullWidth
                label="Main URL"
                placeholder="Insert URL Here"
              />
            </Grid>

            {/* Account Name */}
            <Grid item xs={12} sm={6}>
              <TextField
                name="accountName"
                control={control}
                defaultValue=""
                fullWidth
                label="Account Name"
                placeholder="Insert Name"
              />
            </Grid>

            {/* Category */}
            <Grid item xs={12} sm={6}>
              <InputLabel id="category-label">Category</InputLabel>
              <FormControl fullWidth>
                <Controller
                  name="category"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={categoryOptions}
                      getOptionLabel={(option) =>
                        socialMediaCategoryMapper[option as SocialMediaCategory] ||
                        (option as string)
                      }
                      onChange={(_, value) => field.onChange(value)}
                      renderInput={(params) => <MuiTextField {...params} />}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            {/* Post Type */}
            <Grid item xs={12} sm={6}>
              <InputLabel id="case-type-label">Post Type</InputLabel>
              <FormControl fullWidth>
                <Controller
                  name="postType"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={Object.values(PostType)}
                      getOptionLabel={(option) =>
                        postTypeMapper[option as PostType] || (option as string)
                      }
                      onChange={(_, value) => field.onChange(value)}
                      renderInput={(params) => <MuiTextField {...params} />}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            {/* Case State */}
            <Grid item xs={12} sm={6}>
              <InputLabel id="case-state-label">Case State</InputLabel>
              <FormControl fullWidth>
                <Controller
                  name="caseState"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={Object.values(CaseState)}
                      getOptionLabel={(option) =>
                        caseStateMapper[option as CaseState] || (option as string)
                      }
                      onChange={(_, value) => field.onChange(value)} // To update the value in the form
                      renderInput={(params) => <MuiTextField {...params} />}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button onClick={onClose} type="button" variant="text" color="secondary">
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              loading={createSocialMediaLinksMutation.isLoading}
              variant="contained"
              color="primary"
            >
              Add
            </LoadingButton>
          </Box>
        </form>
      </Modal>
    </>
  );
};
export default AddSocialMediaLink;
