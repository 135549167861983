import React from 'react';
import { Chip } from '@mui/material';
import { ComplianceStatus } from '@services/types/api';

interface Props {
  status: ComplianceStatus;
}

const ComplianceStatusChip = ({ status }: Props) => {
  switch (status) {
    case ComplianceStatus.Compliant:
      return <Chip label="Compliant" color="success" variant="filled" />;
    case ComplianceStatus.NonCompliant:
      return <Chip label="Non-Compliant" color="error" variant="filled" />;
    default:
      return status;
  }
};

export default React.memo(ComplianceStatusChip);
