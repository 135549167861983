import { Button, Modal } from '@atoms';
import DriveFileRenameOutlineSharpIcon from '@mui/icons-material/DriveFileRenameOutlineSharp';
import { Control, useForm } from 'react-hook-form';
import {
  EditMultipleSocialMediaLinkInput,
  editMultipleSocialMediaLinkInputSchema,
  useEditMultipleSocialMediaLinks,
} from '../api/edit-multiple-social-links';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState } from 'react';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import {
  CaseState,
  caseStateMapper,
  ComplianceStatus as ComplianceStatusEnum,
  complianceStatusMapper,
  PostType,
  postTypeMapper,
  SocialMediaCategory,
  socialMediaCategoryMapper,
  SocialMediaLink,
} from '@services/types/api';
import { removedCellClassName } from '@helpers';
import DropdownController from './dropdown-controller';
import { defaultFormControlStyle, StyledBox, StyledFormHelperText } from './styles';
import { IGetColumnData } from './social-media-list';
import { categoryOptions } from './constants';
import { Table } from '@molecules';
import { errorMessage, successMessage, TableSelectType } from '@services';
import { Box } from '@mui/material';
import InteractButton from './interact-button';
import { useParams } from 'react-router-dom';

interface IGetColumnDataMultipleRow extends Pick<IGetColumnData, 'categoryOptions' | 'errors'> {
  control: Control<EditMultipleSocialMediaLinkInput>;
}

interface IEditMultipleSocialLink {
  listEdit: SocialMediaLink[];
  idSelected: string[];
}

interface InteractButtonHandlers {
  handleClickUpdate: () => Promise<void>;
  handleClickCancel: () => void;
}

const MINIMUM_ROW_TO_MULTIPLE_EDIT = 2;

const renderInteractButtonList = ({
  handleClickUpdate,
  handleClickCancel,
}: InteractButtonHandlers) => [
  {
    text: 'Cancel',
    sx: {
      color: '#A3A7B0',
      '&:hover': {
        opacity: 0.8,
      },
    },
    variant: 'text',
    handleClick: handleClickCancel,
  },
  {
    text: 'Update',
    sx: {
      color: '#fff',
      backgroundColor: '#44AEFB',
      '&:hover': {
        backgroundColor: '#44AEFB',
        opacity: 0.8,
      },
    },
    variant: 'contained',
    handleClick: handleClickUpdate,
  },
];

const getColumns = ({
  categoryOptions,
  control,
  errors,
}: IGetColumnDataMultipleRow): GridColDef<SocialMediaLink>[] => {
  return [
    {
      field: 'category',
      headerName: 'Category',
      minWidth: 133,
      flex: 1,
      cellClassName: removedCellClassName,
      valueGetter: (params: GridValueGetterParams) => params.row.category,
      renderCell: () => {
        return (
          <StyledBox>
            <DropdownController
              name="category"
              sx={{ ...defaultFormControlStyle }}
              control={control}
              defaultValue=""
              options={categoryOptions}
              getOptionLabel={(option) =>
                socialMediaCategoryMapper[option as SocialMediaCategory] || (option as string)
              }
              errors={errors.category}
            />
            <StyledFormHelperText>{errors.category?.message}</StyledFormHelperText>
          </StyledBox>
        );
      },
    },
    {
      field: 'postType',
      headerName: 'Post Type',
      minWidth: 94,
      flex: 1,
      cellClassName: removedCellClassName,
      valueGetter: (params: GridValueGetterParams) => params.row.postType,
      renderCell: () => (
        <StyledBox>
          <DropdownController
            name="postType"
            sx={{ ...defaultFormControlStyle }}
            control={control}
            defaultValue=""
            options={Object.values(PostType)}
            getOptionLabel={(option) => postTypeMapper[option as PostType] || (option as string)}
            errors={errors.postType}
          />
          <StyledFormHelperText>{errors.postType?.message}</StyledFormHelperText>
        </StyledBox>
      ),
    },
    {
      field: 'caseState',
      headerName: 'Case State',
      minWidth: 126,
      flex: 1,
      cellClassName: removedCellClassName,
      valueGetter: (params: GridValueGetterParams) => params.row.caseState,
      renderCell: () => (
        <StyledBox>
          <DropdownController
            name="caseState"
            sx={{ ...defaultFormControlStyle }}
            control={control}
            defaultValue=""
            options={Object.values(CaseState)}
            getOptionLabel={(option) => caseStateMapper[option as CaseState] || (option as string)}
            errors={errors.caseState}
          />
          <StyledFormHelperText>{errors.caseState?.message}</StyledFormHelperText>
        </StyledBox>
      ),
    },
    {
      field: 'complianceStatus',
      headerName: 'Compliance Status',
      minWidth: 181,
      flex: 1,
      cellClassName: removedCellClassName,
      valueGetter: (params: GridValueGetterParams) => params.row.complianceStatus,
      renderCell: () => (
        <StyledBox>
          <DropdownController
            name="complianceStatus"
            sx={{ ...defaultFormControlStyle }}
            control={control}
            defaultValue=""
            options={Object.values(ComplianceStatusEnum)}
            getOptionLabel={(option) =>
              complianceStatusMapper[option as ComplianceStatusEnum] || (option as string)
            }
            errors={errors.complianceStatus}
          />
          <StyledFormHelperText>{errors.complianceStatus?.message}</StyledFormHelperText>
        </StyledBox>
      ),
    },
  ];
};

const EditMultipleSocialLink = ({ listEdit, idSelected }: IEditMultipleSocialLink) => {
  const [open, setOpen] = useState(false);

  const {
    reset,
    control,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<EditMultipleSocialMediaLinkInput>({
    resolver: yupResolver(editMultipleSocialMediaLinkInputSchema),
    mode: 'onChange',
    reValidateMode: 'onSubmit',
  });
  const { assetId } = useParams();

  const columns: GridColDef<SocialMediaLink>[] = useMemo(
    () =>
      getColumns({
        categoryOptions,
        control,
        errors,
      }),
    [control, errors],
  );

  const editMultipleSocialMediaLinksMutation = useEditMultipleSocialMediaLinks({
    mutationConfig: {
      onSuccess: () => {
        successMessage('Social Media Link has been edited!');
        onClose();
      },
      onError: () => {
        errorMessage('Failed to edit Social Media Link');
      },
    },
  });

  const onClose = () => {
    reset();
    setOpen(false);
  };

  const handleClickEditMultipleRow = () => {
    if (listEdit.length < MINIMUM_ROW_TO_MULTIPLE_EDIT) return;
    setOpen(true);
  };

  const handleClickUpdate = async () => {
    const isValid = await trigger();
    if (Object.keys(errors).length !== 0 || !isValid) return;
    if (!assetId) {
      errorMessage('Model could not be found');
      return;
    }
    const payload = {
      ...getValues(),
      ids: idSelected,
    };
    editMultipleSocialMediaLinksMutation.mutate({ data: payload, modelId: assetId });
  };

  const handleClickCancel = () => {
    onClose();
  };

  return (
    <>
      <Button
        onClick={handleClickEditMultipleRow}
        sx={{ paddingX: '30px' }}
        variant="outlined"
        color="black"
        startIcon={<DriveFileRenameOutlineSharpIcon />}
      >
        Edit
      </Button>
      <Modal
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '913px',
          },
        }}
        maxWidth={false}
        fullWidth
        title="Edit"
        onClose={onClose}
        open={open}
      >
        <Table<SocialMediaLink>
          dataTableWithoutCallingAPI={[listEdit[0]]}
          columns={columns}
          selectedRowType={TableSelectType.SocialMediaLink}
          withoutSearch
          checkHidePagination
          customSxForTable={{
            '& .MuiDataGrid-cell': {
              borderBottom: 'none',
            },
          }}
        />
        <Box display="flex" justifyContent="end" gap="14px">
          {renderInteractButtonList({ handleClickUpdate, handleClickCancel }).map(
            ({ text, sx, variant, handleClick }) => (
              <InteractButton
                onClick={handleClick}
                key={text}
                sx={sx}
                text={text}
                variant={variant as 'text' | 'outlined' | 'contained'}
                disabled={editMultipleSocialMediaLinksMutation.isLoading}
              />
            ),
          )}
        </Box>
      </Modal>
    </>
  );
};
export default EditMultipleSocialLink;
