import * as React from 'react';

type FiltersStateType = {
  keyword?: string;
  searchByColumns?: string;
};
interface FilterContextType {
  filters: FiltersStateType;
  setFilters: React.Dispatch<React.SetStateAction<FiltersStateType>>;
}

interface FilterProviderProps {
  children: React.ReactNode;
}

const FilterContext = React.createContext<FilterContextType | undefined>(undefined);

export const SocialLinksFilterProvider: React.FC<FilterProviderProps> = ({ children }) => {
  const [filters, setFilters] = React.useState<FiltersStateType>({});

  return (
    <FilterContext.Provider value={{ filters, setFilters }}>{children}</FilterContext.Provider>
  );
};

export const useSocialLinksFiltersContext = () => {
  const context = React.useContext(FilterContext);

  if (context === null) {
    throw new Error('useSocialLinksFiltersContext must be used within a SocialLinksFilterProvider');
  }

  return context;
};
