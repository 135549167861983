import { FC } from 'react';

import { BaseIcon } from '../BaseIcon';
import { SvgIconProps } from '@mui/material';

export const HistoryIcon: FC<SvgIconProps> = (props) => (
  <BaseIcon {...props}>
    <path d="M11.2919 7.83998C11.8393 7.83998 12.3668 7.93224 12.8573 8.10138V1.74166C12.8573 1.14044 12.3698 0.654541 11.7701 0.654541H1.08809C0.486874 0.654541 0.000976562 1.14198 0.000976562 1.74166V14.5534C0.000976562 15.1546 0.488411 15.6405 1.08809 15.6405H7.55237C6.89118 14.8178 6.49447 13.7738 6.49447 12.6359C6.49447 9.98807 8.64256 7.83998 11.2919 7.83998ZM1.78465 4.20497C1.78465 3.8221 2.09525 3.51303 2.47659 3.51303H10.2402C10.6231 3.51303 10.9321 3.82364 10.9321 4.20497C10.9321 4.58785 10.6215 4.89692 10.2402 4.89692H2.47659C2.09525 4.89692 1.78465 4.58785 1.78465 4.20497ZM1.78465 7.60625C1.78465 7.22338 2.09525 6.91431 2.47659 6.91431H7.01727C7.40014 6.91431 7.70921 7.22492 7.70921 7.60625C7.70921 7.98913 7.39861 8.2982 7.01727 8.2982H2.47659C2.09525 8.2982 1.78465 7.98913 1.78465 7.60625ZM5.95014 11.0075C5.95014 11.3904 5.63954 11.6995 5.2582 11.6995H2.47659C2.09372 11.6995 1.78465 11.3889 1.78465 11.0075C1.78465 10.6247 2.09525 10.3156 2.47659 10.3156H5.2582C5.63954 10.3156 5.95014 10.6262 5.95014 11.0075Z" />
    <path d="M11.2923 8.92862C9.24416 8.92862 7.5835 10.5893 7.5835 12.6374C7.5835 14.6856 9.24416 16.3462 11.2923 16.3462C13.3405 16.3462 15.0011 14.6856 15.0011 12.6374C15.0011 10.5893 13.3405 8.92862 11.2923 8.92862ZM13.5711 12.9603C13.5711 13.1925 13.3835 13.3801 13.1513 13.3801H11.1693C10.9371 13.3801 10.7495 13.1925 10.7495 12.9603V10.2418C10.7495 10.0096 10.9371 9.82199 11.1693 9.82199C11.4015 9.82199 11.5891 10.0096 11.5891 10.2418V12.5406H13.1498C13.382 12.5406 13.5711 12.7297 13.5711 12.9603Z" />
  </BaseIcon>
);
