import { useState, useCallback, ReactNode, useEffect, useMemo } from 'react';
import {
  DataGridPro,
  GridSortModel,
  GridColDef,
  DataGridProProps,
  GridEventListener,
} from '@mui/x-data-grid-pro';
import { useQuery } from 'react-query';

import { Box } from '@mui/material';
import { SxProps } from '@mui/system';

import { FilterButton, TableSearch } from '@molecules';
import { Axios, formatQueries, queryClient, queryFormatted } from '@helpers';
import { FilterItem, Queries, TableSelectType } from '@services';
import { NoRowsOverlay } from '@atoms';
import { DEFAULT_QUERIES, useTableQueries } from '@hooks';
import { TableWrap } from '@templates';
import { SelectedRow } from './SelectedRow';

const DEFAULT_TABLE_HEIGHT = 629;
const TABLE_HEADER_HEIGHT = 60;
const TABLE_SPACING_BOTTOM_HEIGHT = 60;

interface TableProps<T> extends Omit<DataGridProProps, 'onRowClick' | 'rows'> {
  mocks?: T[]; // JUST FOR DEVELOPMENT
  columns: GridColDef[];
  queryName?: string;
  apiRoute?: string;
  filtersList?: FilterItem[];
  onRowClick?: (row: T, event?: any) => void;
  selectedRowType?: TableSelectType;
  getSelectedValue?: (items: T[], selectedItems: string[]) => string;
  getSelectedMultipleValue?: (items: T[], selectedItems: string[]) => any;
  checkboxSelection?: boolean;
  refetch?: () => void;
  refetchQueryName?: string;
  searchPlaceholder?: string;
  withoutSearch?: boolean;
  preSelectedFilters?: { [key in string]: string | undefined };
  tableSx?: SxProps;
  renderAdditionalControl?: ReactNode;
  renderRowPerPageOptions?: boolean;
  defaultPageSize?: number;
  renderCustomData?: (items: T[] | undefined) => T[] | any;
  treeData?: boolean;
  getTreeDataPath?: (row: T) => string[];
  groupingColDef?: any;
  getRowClassName?: any;
  onCellClick?: GridEventListener<'cellClick'>;
  rowHeight?: number;
  checkHidePagination?: boolean;
  modelId?: string;
  triggerRefetch?: boolean;
  customHeight?: boolean;
  showFilterBySelect?: boolean;
  filterBySelectOptions?: { value: string; label: string }[];
  customSxForTable?: SxProps;
  dataTableWithoutCallingAPI?: T[];
}

export const Table = <T extends object>({
  mocks,
  queryName = 'name',
  apiRoute = '',
  columns,
  filtersList,
  onRowClick,
  selectedRowType,
  getSelectedValue = () => '',
  // eslint-disable-next-line
  getSelectedMultipleValue = () => {},
  checkboxSelection,
  refetch: additionalRefetch,
  refetchQueryName,
  searchPlaceholder,
  withoutSearch,
  showFilterBySelect,
  filterBySelectOptions,
  preSelectedFilters,
  tableSx,
  renderAdditionalControl,
  renderRowPerPageOptions = true,
  defaultPageSize,
  renderCustomData,
  treeData,
  getTreeDataPath,
  groupingColDef,
  getRowClassName,
  onCellClick,
  rowHeight = 60,
  checkHidePagination = false,
  modelId,
  triggerRefetch,
  customHeight,
  customSxForTable,
  dataTableWithoutCallingAPI,
}: TableProps<T>) => {
  const [selectedItems, setSelectedItems] = useState<Array<string>>([]);
  const {
    queries,
    setQueries,
    filterChange,
    resetFilter,
    filters,
    setFilters,
    selectedFilters,
    setSelectedFilters,
  } = useTableQueries(preSelectedFilters);

  const { data, isLoading, refetch } = useQuery<
    unknown,
    unknown,
    { items: T[]; totalCount: number }
  >(
    [queryName, queries, filters, modelId],
    () =>
      apiRoute &&
      Axios.get(
        `${apiRoute}${queryFormatted(formatQueries({ ...queries, ...filters }, modelId))}`,
      ).then((res) => res.data),
  );

  useEffect(() => {
    if (triggerRefetch) {
      refetch();
    }
  }, [triggerRefetch, refetch]);

  const totalCount = data?.totalCount || 0;
  const rowsPerPageOptions = totalCount
    ? [10, 20, 50, 100, totalCount]
        .filter((item) => item <= totalCount)
        .filter((v, i, a) => a.indexOf(v) === i) // get unique
    : [10, 20, 50, 100];

  const handleRefetch = () => {
    refetch();
    setSelectedItems([]);
    // eslint-disable-next-line no-unused-expressions
    additionalRefetch && additionalRefetch();
    if (refetchQueryName) {
      queryClient.refetchQueries(refetchQueryName);
    }
  };

  const sortModelChange = useCallback(([sortModel]: GridSortModel) => {
    const { sort, field } = sortModel || {};

    setQueries((prevQueries) => ({
      ...prevQueries,
      sortBy: field || '',
      sortDirection: sort || null,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const noRows = useCallback(
    () => (
      <NoRowsOverlay
        filterText={queries.filterText}
        hasSelectedFilters={!!selectedFilters.length}
      />
    ),
    [queries.filterText, selectedFilters],
  );

  const items =
    mocks ||
    dataTableWithoutCallingAPI ||
    (renderCustomData && renderCustomData(data?.items)) ||
    data?.items?.map((item: any, index) => ({ id: index, ...item })) ||
    []; // TODO handle all ids from BE
  const hasSelectedFilters = filtersList?.some(({ key }) => selectedFilters.includes(key));
  const selected = getSelectedValue(items, selectedItems);
  const multipleSelected = getSelectedMultipleValue(items, selectedItems);
  const pageSize = queries.size > totalCount ? totalCount : queries.size;

  const tableHeight = useMemo(() => {
    const totalCount = data?.totalCount || 0;
    if (!totalCount) {
      return 300;
    } else if (totalCount <= DEFAULT_QUERIES.size) {
      return totalCount * rowHeight + TABLE_HEADER_HEIGHT + TABLE_SPACING_BOTTOM_HEIGHT;
    } else {
      return DEFAULT_TABLE_HEIGHT;
    }
  }, [data?.totalCount, rowHeight]);

  const hidePagination = useMemo(() => {
    const totalCount = data?.totalCount || 0;
    if (checkHidePagination && totalCount <= DEFAULT_QUERIES.size) {
      return true;
    }
    return false;
  }, [checkHidePagination, data?.totalCount]);

  return (
    <>
      {!withoutSearch && (
        <Box display="flex" gap="24px" mb="24px" alignItems="flex-start">
          <TableSearch
            placeholder={searchPlaceholder}
            defaultSearch={queries.filterText}
            showFilterBySelect={showFilterBySelect}
            filterBySelectOptions={filterBySelectOptions}
            onSearch={(filterText) =>
              setQueries((prev: Queries) => ({
                ...prev,
                filterText,
              }))
            }
          />
          {filtersList && (
            <FilterButton
              options={filtersList.filter(({ key }) => !selectedFilters.includes(key))}
              onSelect={(key) => setSelectedFilters([...selectedFilters, key])}
              hasSelected={hasSelectedFilters}
              disabled={!filtersList.filter(({ key }) => !selectedFilters.includes(key)).length}
            />
          )}
          {renderAdditionalControl}
        </Box>
      )}
      {filtersList && !!selectedFilters?.length && (
        <Box display="flex" gap="24px" mb="24px">
          {filtersList?.map(({ key, getFilter, resetFields }) => {
            if (selectedFilters?.includes(key)) {
              return getFilter(
                filterChange,
                () => {
                  resetFilter(resetFields || [key]);
                  setSelectedFilters(selectedFilters.filter((f) => f !== key));
                },
                setFilters,
                filters,
              );
            }

            return null;
          })}
        </Box>
      )}
      {!!selectedItems?.length && (
        <SelectedRow
          value={selected}
          multipleValue={multipleSelected}
          items={selectedItems}
          count={selectedItems.length}
          cancel={() => setSelectedItems([])}
          refetch={handleRefetch}
          type={selectedRowType}
        />
      )}
      <TableWrap height={`${customHeight ? tableHeight : DEFAULT_TABLE_HEIGHT}px`} sx={tableSx}>
        <DataGridPro
          rows={items}
          columns={columns}
          loading={isLoading}
          pageSize={defaultPageSize || pageSize}
          page={queries.page}
          onSortModelChange={sortModelChange}
          sortModel={
            queries.sortBy && queries.sortDirection
              ? [{ field: queries.sortBy, sort: queries.sortDirection }]
              : []
          }
          rowCount={data?.totalCount || 0}
          onPageChange={(page) => setQueries({ ...queries, page })}
          rowsPerPageOptions={renderRowPerPageOptions ? rowsPerPageOptions : []}
          pagination={data?.totalCount !== 0}
          onPageSizeChange={(size) => setQueries({ ...queries, size })}
          onRowClick={({ row }, event) => onRowClick && onRowClick(row, event)}
          onCellClick={onCellClick}
          components={{
            NoRowsOverlay: noRows,
          }}
          onSelectionModelChange={(ids) => setSelectedItems(ids as string[])}
          selectionModel={selectedItems}
          checkboxSelection={checkboxSelection}
          filterMode="server"
          sortingMode="server"
          paginationMode="server"
          hideFooterPagination={hidePagination}
          disableSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          disableColumnReorder
          hideFooterSelectedRowCount
          keepNonExistentRowsSelected
          treeData={treeData}
          getTreeDataPath={getTreeDataPath}
          groupingColDef={groupingColDef}
          getRowClassName={getRowClassName}
          rowHeight={rowHeight}
          sx={customSxForTable}
        />
      </TableWrap>
    </>
  );
};
